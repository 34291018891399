import { Snackbar } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';

type ToastProps = {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
};

export const Toast = function ({
  isOpen,
  onClose,
  message,
  severity,
}: ToastProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isOpen}
      onClose={onClose}
      autoHideDuration={6000}
    >
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};
