import Alert from "@material-ui/lab/Alert";
import * as React from "react";

interface MessageProps {
  display: boolean;
  message: string;
  type: "success" | "error";
}

export const Message = function (props: MessageProps) {
  return (
    <Alert
      variant="outlined"
      severity={props.type}
      style={{ display: props.display ? "" : "none" }}>
      {props.message}
    </Alert>
  );
};
