import { Alert, AlertTitle } from '@material-ui/lab';

type PromptMessageProps = {
  message: string;
};

export const PromptMessage = function ({ message }: PromptMessageProps) {
  return (
    <Alert severity="error">
      <AlertTitle style={{ alignContent: 'center', fontSize: '15px' }}>
        {message}
      </AlertTitle>
    </Alert>
  );
};
