import * as React from "react";
import { useState } from "react";
import axios from "axios";
import { Box, Container, CssBaseline, Grid, Link, Paper, TextField, Typography, } from "@material-ui/core";
import { createStyles, createTheme, makeStyles, Theme, ThemeProvider, } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import { AuthFormVariants, IAuthForm } from "../../SharedTypes";
import { MailGeniusButton } from "../buttons/MailGeniusButton";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    formTitle: {
      fontWeight: 500,
    },
    formLink: {
      cursor: "pointer",
      color: '#1E79CB'
    },
  })
);

export const ResetPassword = (props: IAuthForm) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const theme = createTheme();

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setSubmitted(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setErrors({});
    let token = document.querySelector(
      'meta[name="csrf-token"]'
    ) as HTMLMetaElement;

    axios
      .post(
        "/users/password.json",
        {
          user: {
            email: email ? email : null,
            remember_me: 0,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": token.content,
          },
        }
      )
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => {
        if (error.response) {
          const errors = error.response.data.errors;

          setSubmitted(false);
          setErrors(errors);
        }
      });
  };

  const ResetPasswordForm = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            error={"email" in errors}
            helperText={"email" in errors ? errors["email"] : ""}
            required
            fullWidth
            type="email"
            id="email"
            onChange={handleEmail}
            label="Email Address"
            name="email"
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12}>
          <MailGeniusButton type="submit" fullWidth variant="contained">
            Continue
          </MailGeniusButton>
        </Grid>
      </Grid>
    );
  };

  const SuccessMessage = () => {
    return (
      <div className="success" style={{ display: "" }}>
        <Alert severity="success">
          <AlertTitle style={{ alignContent: "start" }}>
            We emailed you a link to reset your password.
          </AlertTitle>
        </Alert>
      </div>
    );
  };

  return (
    <Paper
      elevation={20}
      style={{ padding: "20px", maxWidth: "450px", margin: "20px auto" }}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Typography
              component="h1"
              variant="h5"
              className={classes.formTitle}>
              Reset Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              style={{ marginTop: 3 }}>
              {submitted ? SuccessMessage() : ResetPasswordForm()}
              <Grid
                item
                style={{ marginTop: 20, marginBottom: 2, textAlign: "center" }}>
                <Link
                  className={classes.formLink}
                  onClick={() => props.onFormSwitch(AuthFormVariants.sign_in)}
                  variant="body2">
                  Remember your password? Sign in here.
                </Link>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Paper>
  );
};
