import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  createStyles,
  createTheme,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { Message } from './components/Message';
import { AuthFormVariants, IAuthForm } from '../../SharedTypes';
import { MailGeniusButton } from '../buttons/MailGeniusButton';
import { SuccessMessage } from './components/SuccessMessage';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    formTitle: {
      fontWeight: 500,
    },
    formLink: {
      cursor: 'pointer',
      color: '#1E79CB',
    },
  })
);

interface ISignInErrors {
  message: string | null;
}

const ERROR_MESSAGE =
  'You have to confirm your email address before continuing.';

export const SignIn = (props: IAuthForm) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<ISignInErrors>({ message: null });
  const [showPassword, setShowPassword] = React.useState(false);
  const [isUnconfirmed, setIsUnconfirmed] = React.useState(false);
  const theme = createTheme();

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setErrors({ message: null });
    let token = document.querySelector(
      'meta[name="csrf-token"]'
    ) as HTMLMetaElement;

    axios
      .post(
        '/users/sign_in.json',
        {
          user: {
            email: email ? email : null,
            password: password ? password : null,
            remember_me: 0,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': token.content,
          },
        }
      )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          const message = error.response.data.error;
          console.log('err: ', error)
          if (message === ERROR_MESSAGE) setIsUnconfirmed(true);
          setErrors({ message: message });
        }
      });
  };

  let signUpLink = null;
  if (!props.forbidden) {
    signUpLink = (
      <Grid
        item
        style={{ marginTop: 20, marginBottom: 2, textAlign: 'center' }}
      >
        <Link
          className={classes.formLink}
          onClick={() => props.onFormSwitch(AuthFormVariants.sign_up)}
          variant="body2"
        >
          Don't have an account? Sign up here.
        </Link>
      </Grid>
    );
  }

  if (isUnconfirmed) {
    return (
      <Paper
        elevation={20}
        style={{ padding: '20px', maxWidth: '450px', margin: '20px auto' }}
      >
        <SuccessMessage email={email} />
        <Grid
          item
          style={{
            marginTop: 20,
            marginBottom: 2,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
          }}
        >
          <Link
            className={classes.formLink}
            onClick={() => console.log('resend')}
            variant="body2"
          >
            Didn't receive a confirmation email? Resend it.
          </Link>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper
      elevation={20}
      style={{ padding: '20px', maxWidth: '450px', margin: '20px auto' }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className="messages">
            {errors['message'] != null
              ? Message({
                  type: 'error',
                  display: true,
                  message: errors['message'],
                })
              : null}
          </div>

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              className={classes.formTitle}
            >
              Sign in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              style={{ marginTop: 3 }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    error={'email' in errors}
                    helperText={'email' in errors ? errors['email'] : ''}
                    required
                    fullWidth
                    type="email"
                    id="email"
                    onChange={handleEmail}
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    error={'password' in errors}
                    helperText={'password' in errors ? errors['password'] : ''}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    onChange={handlePassword}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MailGeniusButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10, marginBottom: 2 }}
                  >
                    Continue
                  </MailGeniusButton>
                </Grid>
              </Grid>
              <Grid container style={{ justifyContent: 'flex-end' }}>
                <Grid item>
                  <Link
                    className={classes.formLink}
                    onClick={() =>
                      props.onFormSwitch(AuthFormVariants.reset_password)
                    }
                    variant="body2"
                  >
                    Forgot your password?
                  </Link>
                </Grid>
              </Grid>
              {signUpLink}
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Paper>
  );
};
