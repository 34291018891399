import * as React from "react";
import * as ReactDOM from "react-dom";
import { AuthScreen } from "../mail_tester/pages/AuthScreen";
import { DAuthFormVariantsGuard, DMailAuthLimitsPlanWrapperGuard } from "../mail_tester/Decoders";
import { AuthFormVariants } from "../mail_tester/SharedTypes";

document.addEventListener("turbolinks:load", () => {
  const mountNode = document.getElementById("react-mount-Auth");

  if (mountNode) {
    const form: string = mountNode.dataset.form || 'sign_in';
    const limitsPlan = mountNode.dataset.limits_plan;

    try {
      DAuthFormVariantsGuard(form)

      let parsedForm = AuthFormVariants.sign_in;
      if (form === 'sign_in' || form === 'sign_up' || form === 'reset_password' || form === 'edit_password') {
        parsedForm = AuthFormVariants[form];
      }

      let forbidden = false;
      if (limitsPlan) {
        const parsedLimitsPlan = JSON.parse(limitsPlan);
        DMailAuthLimitsPlanWrapperGuard(parsedLimitsPlan);
        forbidden = parsedLimitsPlan.forbidden;
      }

      let props = {
        form: parsedForm,
        forbidden: forbidden
      };

      ReactDOM.render(<AuthScreen {...props} />, mountNode);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  }
});
