import { IAspect } from "./components/Aspect";

// TODO Jason has 'NONE' severity as an option for Aspect (not factor), need to reconcile this
export enum Severity {
  High = "High",
  Medium = "Medium",
  Low = "Low",
  NONE = "NONE",
}

export enum SubscriptionStatus {
  Incomplete = "incomplete",
  Active = "active",
  Free = "free",
  IncompleteExpired = "incomplete_expired",
  PastDue = "past_due",
  Unpaid = "unpaid",
  Canceled = "canceled",
}

export enum SubscriptionPlanName {
  Newbie = "Newbie",
  Standard = "Standard",
  Genius = "Genius",
  DomainMonitoring = "Automatic Domain Monitoring",
  AutomaticEmailTesting = "Automatic Email Testing",
  Enterprise = "Enterprise Plan",
  MonitoringDomainQuota = "Monitoring Domain Quota",
}

export enum InvoiceStatus {
  Draft = "draft",
  Open = "open",
  Paid = "paid",
  Void = "void",
  Uncollectible = "uncollectible",
  PaymentFailed = "payment_failed",
}

export enum MailTesterTab {
  Good = "good",
  Bad = "bad",
  Personalize = "personalize",
  EmailPreview = "emailPreview",
  Pro = "pro",
}

export enum AspectStatus {
  Pass = "Pass",
  Fail = "Fail",
  "Could be Better" = "Could be Better",
}

export enum AuthFormVariants {
  sign_in = "sign_in",
  sign_up = "sign_up",
  reset_password = "reset_password",
  edit_password = "edit_password",
}

export enum MailTesterStatus {
  Ready = "Ready", // Happy path
  Running = "Running", // While pipeline is still running
  Waiting = "Waiting", // waiting for the email
  Error = "Error",
  Unavailable = "Unavailable",
  NotReady = "NOT_READY",
}

export enum accessType {
  Admin = "ADMIN",
  User = "USER",
}

export const ADMIN_MONITORED_DOMAINS_URL = "/admin/monitored_domains";
export const USER_MONITORED_DOMAINS_URL = "/monitored_domains";

export const MONITORED_DOMAINS_URL = {
  [accessType.Admin]: ADMIN_MONITORED_DOMAINS_URL,
  [accessType.User]: USER_MONITORED_DOMAINS_URL,
} as const;

export interface IMailTesterNotReady {
  status: "NOT_READY";
  slug: string;
}

export interface IProductMetadata {
  slugs_amount: string;
  features: string[];
}

export interface IProduct {
  id: string;
  name: SubscriptionPlanName;
  metadata: IProductMetadata;
}

export interface IProductPrice {
  id: string;
  unit_amount: number;
  recurring: string;
  product: IProduct;
  most_popular: boolean;
  selected?: boolean;
}

export interface IMailTesterLimit {
  status: "LIMIT";
  slug: string;
  forbidden?: boolean;
}

export interface IMailTesterError {
  status: "ERROR";
  slug: string;
}

export interface IMailTesterPaymentFailed {
  status: "PAYMENT_FAILED";
  slug: string;
}

export type ApiKey = {
  id: number;
  name: string;
  limit: number;
  costPerTest: number;
  requestTotalCost: number;
  requestsIn24Hours: number;
  createdAt: string;
  token: string;
  deleted: boolean;
};

export type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  externallyAddedSlugsAmount: number;
};

export type TestEmail = {
  email: string;
};

export type TestResultMail = {
  fromEmail?: string;
  rawEmailWithHeaders?: string;
  rawHtmlBody?: string;
  subjectLine?: string;
};

export type TestResultAspect = {
  id: number;
  message?: string;
  passing?: boolean;
  pointsDeducted?: number;
  severity?: Severity;
  status: AspectStatus;
  importance?: string;
  factors: TestResultAspectFactor[];
};

export type TestResultAspectFactor = {
  id: string;
  name?: string;
  passing: boolean;
  passingDescription?: string;
  pointsDeducted?: number;
  severity?: Severity;
  solution?: string;
  status?: MailTesterStatus;
  title?: string;
};

export type TestResult = {
  id: number;
  createdAt?: string;
  isLoading?: boolean;
  score?: number;
  slug: string;
  status: MailTesterStatus;
  mail?: TestResultMail;
  aspects?: IAspect[];
};

export type ErrorMessage = {
  errorMessage: string;
};

export interface CurrentUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  shared_on_fb: boolean;
  shared_on_lin: boolean;
  shared_on_tw: boolean;
  shared_on_ext: boolean;
  shared_on_yt: boolean;
  free_limit_reached: boolean;
  free_tests_popup_shown: boolean;
  role: "admin" | "customer";
}

export interface Subscription {
  id: number;
  stripeId: string;
  slugsAmount: number;
  unlimited: boolean;
  plan: string;
  period_start: string;
  period_end: string;
  status: SubscriptionStatus;
  invoice_status: InvoiceStatus;
  canceled_at?: string;
  canceled_at_period_end: boolean;
}

export interface LimitsPlan {
  used_amount: number;
  total_amount: number | null;
  unlimited: boolean;
}

export type MonitoredDomain = {
  id: number;
  domain: string;
  reportCount: number;
  active: boolean;
  email: string;
  subscriptionId?: number;
};

export type MonitoredDomainResponse = {
  id: number;
  active: boolean;
  admin_email: string;
  given_domain: string;
  parsed_domain: string;
  report_count: number;
  subscription_id: number;
  additional_domain: boolean;
};

export type MonitoredDomainMetaResponse = {
  current_page: number;
  monitored_domains_count: number;
  pages: number;
  previous_page_url: string;
  first_page_url: string;
  next_page: string;
  last_page_url: string;
};

export type MonitoredDomainResponseData = {
  meta: MonitoredDomainMetaResponse;
  monitored_domains: MonitoredDomainResponse[];
};

export type QuotaSubscriptionsMetaResponseData = {
  used_domains: number;
  domain_limit: number;
};

export type QuotaSubscriptionsResponseData = {
  monitored_domain_subscriptions: SubscriptionResponse[];
  monitored_domains: MonitoredDomainResponse[];
};

export type QuotaSubscription = {
  id: number;
  startDate: string;
  endDate: string;
  plan: string;
  stripeId: string;
};

export type SubscriptionResponse = {
  id: number;
  stripe_id: string;
  slugs_amount: number;
  unlimited: boolean;
  plan: string;
  period_start: string;
  period_end: string;
  status: SubscriptionStatus;
  invoice_status: InvoiceStatus;
  canceled_at?: string;
  canceled_at_period_end: boolean;
};

export type QuotaPriceResponseData = {
  id: string;
  most_popular: boolean;
  recurring: "month" | "year";
  selected: boolean;
  unit_amount: number;
  product: {
    id: string;
    name: string;
    metadata: {
      slugs_amount: string;
      features: string[];
    };
  };
}[];

export type QuotaPrice = {
  id: string;
  recurring: "month" | "year";
  price: number;
};

export type QuotaSubscriptionsMeta = {
  usedQuota: number | undefined;
  quotaLimit: number | undefined;
};

export type DrawerMenuItemProps = {
  disabled: boolean;
  opacity: boolean;
  action?: () => void;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  text?: string;
  className?: string;
} & (
  | {
      withTooltip: true;
      tooltipText: string;
      tooltipAction: () => void;
    }
  | {
      withTooltip: false;
    }
);

export interface IMailTesterOwner {
  id: number;
  subject_line?: string;
  email?: string;
  slug: string;
  status?: MailTesterStatus;
  score?: number;
  mailbox_email?: string;
  created_at: string;
  automatic_email: string | null;
}

export interface IAuthForm {
  onFormSwitch: (
    formName:
      | AuthFormVariants.sign_up
      | AuthFormVariants.sign_in
      | AuthFormVariants.reset_password
  ) => void;
  promptText?: string;
  messageButton?: string;
  forbidden?: boolean;
  onEmailResend?: () => void;
  isFromExtension?: boolean;
}

export type AuditSlug = string;

export type PointsDeducted = number;

export const referrals = {
  neverbounce: "https://mbsy.co/neverbounce/MailGenius",
};

export const monthlyPricing = "month";
export const annuallyPricing = "year";

export type PricingPlan = typeof monthlyPricing | typeof annuallyPricing;

export const DOMAIN_ALREADY_MONITORED = "domain_already_monitored";
export const LIMIT_EXCEEDED = "limit_exceeded";

export type MonitoredDomainErrors = {
  response: {
    data: {
      error_code: typeof DOMAIN_ALREADY_MONITORED | typeof LIMIT_EXCEEDED;
    };
  };
};
export type ExtensionConnectionResponse = {
  id: number;
  user_id: number;
  created_at: string;
  updated_at: string;
  extension_email: string;
  status: string;
};

export type ExtensionConnection = {
  id: number;
  userId: number;
  createdAt: string;
  updatedAt: string;
  extensionEmai: string;
  status: string;
};

export type AutomaticEmail = {
  id: number;
  email: string;
  customSlug: string;
  userId: number;
  active: boolean;
  createdAt: string;
  updatedAt: string;
};

export type AutomaticEmailResponse = {
  id: number;
  email: string;
  custom_slug: string;
  user_id: number;
  active: boolean;
  created_at: string;
  updated_at: string;
};

export type AllStatisticsReport = {
  averageTests: number;
  mostTestsOfUser: number;
  totalTests: number;
  totalUsers: number;
  usersWithOnePlusTests: number;
  usersWithTwoPlusTests: number;
  usersWithThreePlusTests: number;
  usersWithFourPlusTests: number;
  usersWithFivePlusTests: number;
  plansCount: {
    [SubscriptionPlanName.Newbie]: number;
    [SubscriptionPlanName.Standard]: number;
    [SubscriptionPlanName.Genius]: number;
    [SubscriptionPlanName.AutomaticEmailTesting]: number;
    [SubscriptionPlanName.MonitoringDomainQuota]: number;
  };
};
export type TestsStatisticsReport = {
  failedTestsCount: number;
  passedTestsCount: number;
  mostOftenFailedTestType: string;
  mostOftenPassedTestType: string;
};

export type FilteredStatisticsReport = {
  averageTestScore: number;
  testsWithMaxScore: number;
  totalTestsCount: number;
  failedTestsCount: number;
  passedTestsCount: number;
  mostOftenFailedTestType: string;
  mostOftenPassedTestType: string;
  averageTests: number;
  dailyUsersCount: DailyUserCount[];
};

export type DailyUserCount = {
  date: string;
  amount: number;
};

export type PassedTestTypeByDate = {
  date: string;
  passedTestTypeId: string;
};

export type FailedTestTypeByDate = {
  date: string;
  failedTestTypeId: string;
};

interface Domain {
  id: number;
  domain: string;
  parsedDomain: string;
}

interface DNSRecord {
  full_record: string;
  value: string;
}

export interface MonitoringReportFactor {
  name: string;
  id: string;
  title: string;
  status: string;
  severity: string;
  solution: string;
  passing: boolean;
  points_deducted: number;
  passing_description: string;
  aspect: string;
}

interface DNS {
  spf: DNSRecord[];
  dkim: DNSRecord[];
  dmarc: DNSRecord[];
}

export interface MonitoringReportPassing {
  count: number;
  factors: {
    [key: string]: MonitoringReportFactor[];
  };
}

interface Failures {
  count: number;
  factors: {
    [key: string]: MonitoringReportFactor[];
  };
}

interface Report {
  dns: DNS;
  passing: MonitoringReportPassing;
  newFailures: Failures;
  repeatFailures: Failures;
}

export interface MonitoringReportJson {
  domain: Domain;
  report: Report;
}

export type Announcement = {
  id: number;
  content: string;
  created_at: string;
  expires_at: string;
  disabled: boolean;
};
