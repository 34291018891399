import { Alert, AlertTitle } from '@material-ui/lab';

type SuccessMessageProps = {
  email?: string;
};

export const SuccessMessage = function ({ email }: SuccessMessageProps) {
  return (
    <div className="success" style={{ display: '' }}>
      <Alert severity="success">
        <AlertTitle style={{ alignContent: 'start' }}>
          {email
            ? `We sent a confirmation email to ${email}`
            : 'We sent you a confirmation email.'}
        </AlertTitle>
        Please click the link in the email to activate your account!
      </Alert>
    </div>
  );
};
