import { TextField } from '@material-ui/core';

type FormInputFieldProps = {
  onChangeFn: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: React.HTMLInputTypeAttribute;
  [props: string]: any;
};

export const FormInputField = function ({
  identifier,
  onChangeFn,
  errors,
  type = 'text',
  ...props
}: FormInputFieldProps) {
  return (
    <TextField
      required
      type={type}
      fullWidth
      onChange={onChangeFn}
      name={identifier}
      id={identifier}
      variant="outlined"
      {...props}
    />
  );
};
