import * as React from "react";
import { ChangeEvent, FormEvent, MouseEvent, useState } from "react";
import axios from "axios";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { createStyles, createTheme, makeStyles, Theme, ThemeProvider, } from "@material-ui/core/styles";
import { Message } from "./components/Message";
import { AuthFormVariants, IAuthForm } from "../../SharedTypes";
import { MailGeniusButton } from "../buttons/MailGeniusButton";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    formTitle: {
      fontWeight: 500,
    },
    formLink: {
      cursor: "pointer",
      color: '#1E79CB'
    },
  })
);

export const EditPassword = (props: IAuthForm) => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const theme = createTheme();

  const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmation = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirmation(e.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation((show) => !show);

  const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const handleMouseDownPasswordConfirmation = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
  };

  const handleSubmit = (e: FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setErrors({});
    setErrorMessage(null);

    let token = document.querySelector(
      'meta[name="csrf-token"]'
    ) as HTMLMetaElement;
    const searchParams = new URLSearchParams(document.location.search);
    const resetPasswordToken = searchParams.get("reset_password_token");

    axios
      .put(
        "/users/password.json",
        {
          user: {
            password: password ? password : null,
            password_confirmation: passwordConfirmation
              ? passwordConfirmation
              : null,
            reset_password_token: resetPasswordToken,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": token.content,
          },
        }
      )
      .then(() => {
        window.location.href = "/users/edit";
      })
      .catch((error) => {
        if (error.response) {
          const responseError = error.response.data.error;
          const responseErrors = error.response.data.errors;
          if (responseError) {
            setErrorMessage(responseError);
          } else if (responseErrors && responseErrors["reset_password_token"]) {
            setErrorMessage(
              "Looks like the link is expired or invalid, please try again"
            );
          } else {
            setErrors(responseErrors);
          }
        }
      });
  };

  return (
    <Paper
      elevation={20}
      style={{ padding: "20px", maxWidth: "450px", margin: "20px auto" }}>
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className="messages">
            {errorMessage != null
              ? Message({
                type: "error",
                display: true,
                message: errorMessage,
              })
              : null}
          </div>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Typography
              component="h1"
              variant="h5"
              className={classes.formTitle}>
              Reset Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              style={{ marginTop: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    error={"password" in errors}
                    helperText={"password" in errors ? errors["password"] : ""}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    onChange={handlePassword}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    error={"password_confirmation" in errors}
                    helperText={
                      "password_confirmation" in errors
                        ? errors["password_confirmation"]
                        : ""
                    }
                    required
                    fullWidth
                    name="password-confirmation"
                    label="Confirm New Password"
                    type={showPasswordConfirmation ? "text" : "password"}
                    id="password-confirmation"
                    onChange={handlePasswordConfirmation}
                    autoComplete="new-password-confirmation"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordConfirmation}
                            onMouseDown={handleMouseDownPasswordConfirmation}
                            edge="end">
                            {showPasswordConfirmation ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MailGeniusButton type="submit" fullWidth variant="contained">
                    Update Password
                  </MailGeniusButton>
                </Grid>
              </Grid>

              <Grid item style={{ marginTop: 20, marginBottom: 2 }}>
                <Link
                  className={classes.formLink}
                  onClick={() => props.onFormSwitch(AuthFormVariants.sign_in)}
                  variant="body2">
                  Remember your password? Sign in here.
                </Link>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Paper>
  );
};
