import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import {
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { SignIn } from '../components/auth/SignInForm';
import { SignUp } from '../components/auth/SignUpForm';
import { ResetPassword } from '../components/auth/ResetPasswordForm';
import { EditPassword } from '../components/auth/EditPasswordForm';
import logo from '../../images/PrimaryLogo.webp';
import { AuthFormVariants } from '../SharedTypes';
import { Toast } from '../components/popups/Toast';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background:
        'radial-gradient(circle, rgb(255, 255, 255) 0%, rgba(22, 169, 226, 0.56) 100%)',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflowY: 'auto',
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
    },
    content: {
      marginTop: '25px',
      textAlign: 'center',
    },
    image: {
      width: '80%',
    },
  })
);

interface IAuthScreen {
  forbidden: boolean;
  form: AuthFormVariants;
  promptText?: string;
  messageButton?: string;
}

export const AuthScreen = function (props: IAuthScreen) {
  const classes = useStyles();
  const [currentForm, setCurrentForm] = useState(props.form);
  const [isEmailResendSuccess, setIsEmailResendSuccess] = useState(false);
  const [isFromExtension, setIsFromExtension] = useState(false);

  const handleEmailResendSuccess = () => {
    setIsEmailResendSuccess(true);
  };

  const handleToastClose = () => {
    setIsEmailResendSuccess(false);
  };

  if (currentForm === AuthFormVariants.sign_up && props.forbidden) {
    return <p>Forbidden</p>;
  }

  const toggleForm = (
    formName:
      | AuthFormVariants.sign_up
      | AuthFormVariants.sign_in
      | AuthFormVariants.reset_password
  ) => {
    setCurrentForm(formName);
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const extensionSignUp = params.get('extensionSignUp');
    if (extensionSignUp === 'true') {
      setIsFromExtension(true);
    }
  }, []);

  const form = useMemo(() => {
    if (currentForm === 'sign_up') {
      return (
        <SignUp
          promptText={props.promptText}
          messageButton={props.messageButton}
          onFormSwitch={toggleForm}
          onEmailResend={handleEmailResendSuccess}
          isFromExtension={isFromExtension}
        />
      );
    } else if (currentForm === 'sign_in') {
      return <SignIn forbidden={props.forbidden} onFormSwitch={toggleForm} />;
    } else if (currentForm === 'reset_password') {
      return <ResetPassword onFormSwitch={toggleForm} />;
    } else if (currentForm === 'edit_password') {
      return <EditPassword onFormSwitch={toggleForm} />;
    }

    return null;
  }, [
    currentForm,
    toggleForm,
    props.forbidden,
    props.promptText,
    props.messageButton,
  ]);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={2} />
          <Grid item xs={8} className={classes.content}>
            <img className={classes.image} src={logo} alt="" />

            {form}
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Container>
      <Toast
        isOpen={isEmailResendSuccess}
        onClose={handleToastClose}
        message="Confirmation email resent"
        severity="success"
      />
    </div>
  );
};
