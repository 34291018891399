import axios from 'axios';

export const resendConfirmationEmail = function (email: string): Promise<void> {
  return new Promise(async (resolve, reject) => {
    try {
      let token = document.querySelector(
        'meta[name="csrf-token"]'
      ) as HTMLMetaElement;
      await axios.get(`/users/resend_confirmation?email=${email}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': token.content,
        },
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
