import { Button } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

export const MailGeniusButton = withStyles((theme: Theme) => ({
    root: {
        color: 'white',
        background: '#1E79CB',
        '&:hover': {
            background: '#1E79CB',
        },
    },
}))(Button);
